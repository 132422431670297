.videoContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 55px;
}

.carTracksContainer {
    display: grid;
    grid-template-columns: minmax(50%, 100%) minmax(50%, 100%);
    grid-template-areas:
        "FL FR"
        "CL CR"
        "BC BC";
    min-height: 0;
    overflow: scroll;
}

.singleCarTrack {
    position: relative;
    min-width: 0;
}

.zoomedImg {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.closeZoom {
    position: absolute;
    top: 15px;
    right: 15px;
}

.singleVideo {
    position: relative;
    display: inline-block;
}

.fullScreen {
    width: 100%;
}

.frameButton {
    position: absolute;
    top: 10px;
    right: 10px;
}

.magnifyButton {
    position: absolute;
    top: 35px;
    right: 10px;
}

.magnifyActive {
    background-color: blue;
}

.magnifyRegular {
    background-color: white;
}

.measure {
    pointer-events: none;
    position: absolute;
    color: red;
    transform: translate(-50%, -50%);
}

.controlBar {
    padding: 10px;
    display: flex;
    align-items: baseline;
}

.cameraCheck {
    display: flex;
    margin-left: 50px;
}

.controlsContainer {
    display: flex;
    justify-content: stretch;
    padding: 5px 0;
    position: sticky;
    bottom: 0;
    background-color: white;
}

.loadingContainer {
    background-color: #ccc;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
}
