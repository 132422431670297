// Default scale line styles by open layer
.custom-scale-line {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  background: var(--ol-partial-background-color);
  border-radius: 4px;
  bottom: 8px;
  left: 8px;
  padding: 2px;
  position: absolute;
}

.custom-scale-line-inner {
  border: 1px solid var(--ol-subtle-foreground-color);
  border-top: none;
  color: var(--ol-foreground-color);
  font-size: 10px;
  text-align: center;
  margin: 1px;
  will-change: contents, width;
  transition: all 0.25s;
}

@media (max-width: 575.98px) {
  .custom-scale-line {
    bottom: 7vh;
    left: 3vw;
  }
}
