.file-upload-container {
  padding: 15px;
}
.file-upload-label {
  display: flex;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #0080B6;
}

.file-upload-text {
  border-radius: 4px 1px 1px 4px;
  padding: 5px 20px;
  color: #0080B6;
  white-space: nowrap;
}

.file-upload-input {
  display: none;
}

.cloud-icon {
  color: #0080B6;
  margin-right: 2%;
}
