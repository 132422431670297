.jsonschema-form {
  .form-group {
    margin-bottom: 1rem;
  }

  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    &:focus {
      border-color: #66afe9;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    }
  }

  .array-item-add .btn-add {
    background-color: #008cd0;
    border-radius: 4px;
    border: 0;
    color: white;
    height: 30px;
    width: 100px;
    cursor: pointer;

    &::after {
      font-family: "Font Awesome 5 Free";
      content: '\f067';
      font-weight: 600;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .array-item-list {
    .array-item {
      display: flex;
    }

    :not(.array-item-toolbox) {
      flex: 1;
    }

    .array-item-toolbox {
      display: flex;
      align-items: center;
      min-width: 150px;

      i.glyphicon {
        display: none;
      }

      .array-item-move-up, .array-item-move-down, .array-item-remove {
        border: 1px solid #ccc;
        border-left: none;
        padding: 10px;

        &:not(:disabled) {
          cursor: pointer;
        }

        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          border-left: 1px solid #ccc;
        }

        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      .array-item-move-up {
        background-color: white;

        &::after {
          font-family: "Font Awesome 5 Free";
          content: '\f062';
        }

        &:not(:disabled):hover {
          background-color: #ccc;
        }
      }

      .array-item-move-down {
        background-color: white;

        &::after {
          font-family: "Font Awesome 5 Free";
          content: '\f063';
        }

        &:not(:disabled):hover {
          background-color: #ccc;
        }
      }

      .array-item-remove {
        background-color: #DC143C;

        &::after {
          font-family: "Font Awesome 5 Free";
          content: '\f00d';
          color: white;
        }

        &:hover {
          background-color: #B22222;
        }
      }
    }
  }

  .form-actions {
    padding-left: 10.5px;
    margin-bottom: 25px;

    .back-btn, .submit-btn {
      margin-right: 5px;
    }
  }

  fieldset {
    border: 0;
    margin: 0;
  }

  select[multiple], select[size] {
    height: auto;
  }

  legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: inherit;
    color: #333;
    border-top-width: 0;
    border-right-width: 0;
    border-bottom: 1px solid #e5e5e5;
    border-left-width: 0;
  }

  input[type="checkbox"], input[type="radio"] {
    margin: 4px 5px 0 0;
    line-height: normal;
  }

  label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 600;
  }
}
