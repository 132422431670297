.offset-panel {
  position: relative;
  display: inline-block;
}

.offset-controls {
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}


.offset-control {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.offset-label {
  width: 50px;
  margin-right: 5px;
}

.offset-value {
  width: 40px;
  text-align: right;
  margin-right: 5px;
}

.offset-buttons {
  display: flex;
}

.offset-buttons button {
  width: 25px;
  height: 25px;
  font-size: 14px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.offset-buttons button:first-child {
  border-radius: 3px 0 0 3px;
}

.offset-buttons button:last-child {
  border-radius: 0 3px 3px 0;
}

.offset-buttons button:hover {
  background-color: #e0e0e0;
}