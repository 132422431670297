.identify-btn{
    margin-top: 42px;
    margin-left: 10px;
}

.identify-btn button.active {
    background-color: #00A1E8;
}

.identify-btn button.focus {
    outline: 1px solid #fff;
}