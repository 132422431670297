.separator {
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
    padding-bottom: 5%;
}

.description {
    font-size: 1rem;
    font-weight: 700;
    color: #666;
}

.slug-input {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 3%;
    margin-bottom: 3%;

    button {
        margin-top: 2%;
    }
}

.regular {
    &:active {
        opacity: 0.7;
        background-color: #ccc;
    }
}